/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "../intercom_for_agents"
import "../hotjar"




// Failed attempt to move RailsAdmin Customization JS and Styles to Webpack from app/assets/

// import "../rails_admin_ui"
// import $ from 'jquery'
// import prepUI from "../rails_admin_ui"

// $(document).on('rails_admin.dom_ready', function(){
// 	console.log("RAILS ADMIN DOM READY. RUN THIS STUFFFFFFFF")
// 	prepUI();
// });