window.intercomSettings = {
  app_id: 	gon.intercom_workspace_id,
  name: 		gon.first_name + " " + gon.last_name,
  email: 		gon.email,
  phone:    gon.phone,
  user_id: 	gon.account_id,
  gender: 	gon.gender,
  birthday: gon.birthday,
  student_count: gon.student_count
};

if (gon.intercom_workspace_id) {
  (function() {
    var w = window;
    var ic = w.Intercom;

    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;

      var i = function() {
        i.c(arguments);
      };

      i.q = [];

      i.c = function(args) {
        i.q.push(args);
      };

      w.Intercom = i;

      var l = function() {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/kf23t9gs";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };

      if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
}