// Don't initialize hotjar script if no site ID (ie: on localhost)
if (gon.hotjar_site_id) {
	(function(h,o,t,j,a,r){
	  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
	  h._hjSettings={hjid: gon.hotjar_site_id,hjsv:6};
	  a=o.getElementsByTagName('head')[0];
	  r=o.createElement('script');r.async=1;
	  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
	  a.appendChild(r);
	})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

